.about {
    background:linear-gradient(to bottom, rgba(0,0,0,.95) 10%, rgba(0,0,0,.9) 35%, rgba(0,0,0,.95)90%), url(../../assets/bg-about.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.carousel-item img{
    border-radius: 10px;
}

.bullet{
    display: flex;
}

.bullet img{
    width: 2em;
    height: 2.2em;
}