

.accordion-item {
background: transparent;
color:#fff ;
border:none;
padding-bottom: 0rem;
background: url(/src/assets/circle.png) no-repeat;
background-position: left 0;


}
h2.accordion-header .accordion-button{
 font-family: var(--textBig);
font-weight: bold;
font-size: 40px;
line-height: 50px;
text-align: center;
letter-spacing: 2px;
border-left: 3px solid #fff;
padding-left:20px;
margin-left: 7px;
color: var(--colorbase);
padding-bottom: 10px;

}

.accordion-item p{
    border-left: 3px solid #fff;
    padding-left:20px;
    margin-left: 7px;
    padding-bottom: 60px;
}

.accordion-collapse{
    transition: height 0.2s ease 0s;
    overflow: hidden;
    max-height: 300px !important;
}


@media(max-width: 768px) {
    h2.accordion-header .accordion-button{
        font-size: 26px;
        text-align: left;
}
}
