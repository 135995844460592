.feature-img{
    width: 100%;
    height: 1000px;
    background:  radial-gradient(54.48% 54.48% at 46.16% 45.52%, rgba(0, 0, 0, 0) 0%, #000000 99.51%), url(../../assets/feature.jpeg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

/* .fadeInFromLeft {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 1s, transform 1s;
  }

  .fadeInFromLeft.active {
    opacity: 1;
    transform: translateX(0);
  } */

  
.box-headings{
    margin-bottom: 3rem;
}