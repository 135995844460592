
section{
    color: white;
    display: flex;
flex-direction: column;
padding: 1em !important;
max-width: 90%;
margin: auto;
}
    h1, h2 {
        font-weight: bold;
    }

    h1 {
        margin-top: 3em;
    }

    h2 {
        /* border-bottom: 1px solid #eee; */
        padding-bottom: 10px;
        /* margin-top: 20px; */
    }

    p {
        margin-bottom: 15px;
    }

    footer {
        /* margin-top: 50px; */
    }