.feature-level{
    width: 100%;
    min-height: 500px;
    background:  url(../../assets/Mission.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
}

/* .fadeInFromLeft {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 1s, transform 1s;
  }

  .fadeInFromLeft.active {
    opacity: 1;
    transform: translateX(0);
  } */

  
.box-headings{
    margin-bottom: 3rem;
}