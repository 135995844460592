.requirements {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1)5%, rgba(0, 0, 0, .8) 45%, rgba(0, 0, 0, 1)90%), url(../../assets/bg-requirements.jpg);
    background-size: cover;
    background-position: center;

}

.requirements .box-headings {
    align-items: center;
    margin-bottom: 5rem;

}

.requirements-grid {
  max-width: 500px;
  margin: 2rem 2rem 6rem;
  
}

.center{
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 10rem; */
  align-items: center;
}

.requirements-grid .col {
    font-family: var(--textbase);
    font-size: 16px;
    line-height: 22px;
    border:1px solid #474747;
    padding: 15px;
    color: #fff;
 
    
}

.headin{
    padding-top: 2em;
}
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  

.requirements-grid .col span{
    font-family: var(--textBig);
    display: block;
    font-size: 22px;
    line-height: 24px;    
    letter-spacing: 2px;
    margin-bottom: .3rem;
    color:var(--colorbase);

}