footer{
    background: #000;
    padding: 5rem 0 0;
}
.footer{
   
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
}

.logofooter img{
    text-align: left;
    width: 160px;

}

.footer ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    justify-content: center;

}
.footer ul li {
    margin: .2rem 1rem;
}
.footer ul li a{
    font-size: 16px;
    color: #fff;
}

.footer ul li a:hover{
    color: #ccc;
    transform: scale(1.1);
}
.footer .icons svg{
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    margin: .2rem;
   
}
.footer .icons svg:hover{
    transform: scale(1.1);
}

.rights{
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 12px;
}
.rights p:last-child{
    cursor: pointer;
}

.medialogo{
    max-width: 1.5em;
}
@media(max-width: 960px) {
    .footer{
        text-align: center;
    }
    
    .footer ul{
        margin: 2rem 0;
        padding: 0;
    }
    .footer ul li a{
        font-size: 14px;
        color: #fff;
    }
}
